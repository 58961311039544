.arrows-down-bg {
  background: url('/common/assets/images/bg-arrows-down.png');
  background-repeat: no-repeat;
  background-size: contain;

  &--fullwidth {
    background-size: cover;
  }
}

.contract-bg {
  background: linear-gradient(314.1deg, #6100e9 -8.36%, #7d008e 100%);
  position: relative;
  &:before {
    content: url('/common/assets/images/bg-fill1.png');
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50%;
    height: auto;
  }
}
