@import 'src/common/assets/styles/variables';

$font-size: 1em;
$icon-size: 3em;
$input-x-padding: 0.5em;

.inputfield {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    padding-left: 2 * $input-x-padding;
  }

  &__wrapper {
    min-height: 3em;

    padding-left: $input-x-padding;
    padding-right: $input-x-padding;

    border-radius: 2.25rem;
    font-size: $font-size;

    &__icon {
      /* Change input padding if icon is present */
      & + input {
        padding-left: $input-x-padding;
      }
    }
  }

  input,
  textarea {
    background: transparent;
    outline: none;
    height: 100%;
    width: 100%;
    flex: 1;
  }

  textarea {
    margin-right: 1.8rem;
    padding: 1.8rem 0 1.8rem 2.5em;

    &::-webkit-scrollbar-track {
      margin: 1rem 0;
    }
  }

  input::placeholder {
    color: white;
    opacity: 0.5;
    font-weight: normal;
  }

  .ErrorUnderline {
    height: 1px;
    width: 0;
    position: absolute;
    bottom: -1px;
    z-index: 1;
    transition: width 0.5s ease;
    left: 50%;
    transform: translateX(-50%);

    &--visible {
      width: calc(100% - (#{$icon-size} + #{$input-x-padding} * 2));
    }
  }
}
