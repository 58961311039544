@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?47625580');
  src: url('../font/fontello.eot?47625580#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?47625580') format('woff2'),
       url('../font/fontello.woff?47625580') format('woff'),
       url('../font/fontello.ttf?47625580') format('truetype'),
       url('../font/fontello.svg?47625580#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?47625580#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone-in-talk:before { content: '\e800'; } /* '' */
.icon-crosshairs-gps:before { content: '\e801'; } /* '' */
.icon-human:before { content: '\e802'; } /* '' */
.icon-back:before { content: '\e803'; } /* '' */
.icon-chevron-up:before { content: '\e804'; } /* '' */
.icon-alert-circle:before { content: '\e805'; } /* '' */
.icon-timer:before { content: '\e806'; } /* '' */
.icon-chevron-left:before { content: '\e807'; } /* '' */
.icon-image:before { content: '\e808'; } /* '' */
.icon-pin-location:before { content: '\e809'; } /* '' */
.icon-info:before { content: '\e80a'; } /* '' */
.icon-cloud-check:before { content: '\e80b'; } /* '' */
.icon-close:before { content: '\e80c'; } /* '' */
.icon-add:before { content: '\e80d'; } /* '' */
.icon-chevron-down:before { content: '\e80e'; } /* '' */
.icon-chevron-right:before { content: '\e80f'; } /* '' */
.icon-checkbox-marked:before { content: '\e810'; } /* '' */
.icon-close-circle:before { content: '\e811'; } /* '' */
.icon-clock:before { content: '\e813'; } /* '' */
.icon-cellphone-android:before { content: '\e814'; } /* '' */
.icon-home:before { content: '\e815'; } /* '' */
.icon-numbers:before { content: '\e816'; } /* '' */
.icon-work:before { content: '\e817'; } /* '' */
.icon-bank:before { content: '\e818'; } /* '' */
.icon-phone:before { content: '\e819'; } /* '' */
.icon-add_a_photo:before { content: '\e81a'; } /* '' */
.icon-download:before { content: '\e81b'; } /* '' */
.icon-calendar-range:before { content: '\e81c'; } /* '' */
.icon-calendar-check:before { content: '\e81d'; } /* '' */
.icon-experience-empty-multiple:before { content: '\e81e'; } /* '' */
.icon-loading:before { content: '\e81f'; } /* '' */
.icon-play:before { content: '\e820'; } /* '' */
.icon-delete:before { content: '\e821'; } /* '' */
.icon-lock:before { content: '\e822'; } /* '' */
.icon-ticket:before { content: '\e823'; } /* '' */
.icon-account:before { content: '\e824'; } /* '' */
.icon-camera:before { content: '\e825'; } /* '' */
.icon-email:before { content: '\e826'; } /* '' */
.icon-check-circle:before { content: '\e827'; } /* '' */
.icon-j:before { content: '\e828'; } /* '' */
.icon-close-round:before { content: '\e829'; } /* '' */
.icon-next:before { content: '\e82a'; } /* '' */
.icon-tie:before { content: '\e82b'; } /* '' */
.icon-support:before { content: '\e82c'; } /* '' */
.icon-calendar-text:before { content: '\e82d'; } /* '' */
.icon-pencil:before { content: '\e82e'; } /* '' */
.icon-dots:before { content: '\e82f'; } /* '' */
.icon-calendar-error:before { content: '\e834'; } /* '' */
.icon-circle:before { content: '\e835'; } /* '' */
.icon-calendar-date:before { content: '\e836'; } /* '' */
.icon-send:before { content: '\e85f'; } /* '' */
.icon-email-open:before { content: '\e860'; } /* '' */
.icon-logout:before { content: '\e861'; } /* '' */
.icon-search:before { content: '\e862'; } /* '' */
