@tailwind base;
@tailwind components;

@import "common/components/Card/base-card.scss";
@import "common/components/Typography/typography.scss";

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "common/assets/icons/css/fontello.css";
@import "common/assets/styles/global";
@import "common/assets/styles/calendar.scss";
@import "common/assets/styles/card-backgrounds.scss";
@import "common/assets/styles/bg-arrows.scss";


html {
  margin: 0;
  font-family: 'Montserrat', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow: hidden;
}

html, body, #root, #root > div.App {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
