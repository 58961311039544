@import '~common/assets/styles/variables';

.card {
  //height: 75%;
  max-height: 45rem;
  min-height: 37rem;
  width: 29.5rem;

  &--default {
    background: linear-gradient(325.1deg, #900093 2.44%, #6a00ab 96.41%);
  }

  &--coins {
    background: #009e46;
  }

  &--error {
    background: linear-gradient(180deg, #ea3477 0%, #c70032 100%);
  }
}

.card_inner {
  max-width: 22rem;
  width: 20.5625rem;
}

.bg img {
  position: absolute;
}

@media only screen and (max-width: $mobile-screen-break) {
  .card {
    width: 100%;
    min-height: 27rem;
  }

  .card_inner {
    width: 18.5rem;
    max-width: 18.5rem;
  }
}
