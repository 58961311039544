@import '~common/assets/styles/variables';

.subtitle {
  height: 42vh;
  max-height: 18rem;

  &::-webkit-scrollbar-track {
    margin-bottom: 2rem;
  }
}

.input {
  outline: none;
  background: transparent;
  font-weight: bold;

  &::placeholder {
    color: white;
    opacity: 0.6;
    font-weight: bold;
  }
}

@media only screen and (max-width: $mobile-screen-break) {
  .subtitle {
    height: 25vh;
  }
}
