@import '~common/assets/styles/variables';

.msg {
  max-width: 16rem;
}
@media only screen and (max-width: $mobile-screen-md-break) {
  .msg {
    display: none;
  }
}
