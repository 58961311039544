.bg-primary2 {
  background-image: linear-gradient(180deg, #24114f 0%, #17073a 100%);
}

.bg-secondary1 {
  background-image: linear-gradient(157.92deg, #026aa5 14.57%, #0e009b 103.42%);
}

.bg-secondary2 {
  background-image: linear-gradient(157.92deg, #2884b8 14.57%, #00654d 103.42%);
}

.bg-secondary3 {
  background-image: linear-gradient(157.92deg, #3f1567 14.57%, #310065 103.42%);
}

.bg-secondary4 {
  background-image: linear-gradient(221.51deg, #0085c1 8.29%, #7c0ace 103%);
}

.bg-secondary5 {
  background-image: linear-gradient(157.92deg, #a27bca 14.57%, #98009b 103.42%);
}

.bg-secondary5 {
  background-image: linear-gradient(157.92deg, #7f805a 14.57%, #169b00 103.42%);
}

// The actual color meant by a white background with an opacity of 0.07
// Try using this if the design doesn't actually show anything under the background,
// But suggests white with low opacity
.bg-purple-faded {
  background-color: #37265c;
}

h1 {
  font-family: Anton;
  font-style: normal;
  font-weight: normal;
  font-size: 3.75rem;
  line-height: 5rem;
}

h2 {
  font-family: Anton;
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 2.25rem;
}

h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 3.125rem;
}

h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

h6 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.subtitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.875;
}

body {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-overline {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-button-2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animate-fill-in {
  animation: fill-in 0.5s linear;

  @keyframes fill-in {
    0% {
      max-width: 0;
    }
    100% {
      max-width: 100vw;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  width: 4px;
  border: none;
  background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #644b98;
}

::-webkit-scrollbar-track {
  background-image: url('../images/scrollbar-track.png');
  background-repeat: repeat-y;
  background-size: contain;
  border-radius: 6px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #644b98 #382367;
}

.scrollbar-white {
  scrollbar-color: white rgb(130, 77, 207);

  &::-webkit-scrollbar-track {
    background-image: url('../images/scrollbar-track-white.png');
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.flex-static {
  flex: 0 0 auto;
}

.animate-slide-in-r {
  animation: 0.5s slide-in-r;
}

.animate-slide-out-r {
  animation: 0.5s slide-out-r;
  transform: translateX(200%);
}

.animate-fade-in {
  animation: 0.5s fade-in;
}

.animate-fade-in-backdrop {
  .animate-backdrop,
  .backdrop-filter {
    animation: 0.5s fade-in;
  }
}

@keyframes slide-in-r {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-r {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(200%);
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-overlay {
  background: rgba(36, 23, 65, 0.8);
  backdrop-filter: blur(20px);
}

.popup-arrow {
  display: none;
}

.b-inner-shadow {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 6rem;
    background: linear-gradient(180deg, rgba(0, 0, 17, 0) 0%, #18073b 100%);
    opacity: 1;
    pointer-events: none;
    border-radius: 0 0 30px 30px;

    animation: 0.5s fade-in;
  }
}

#notifications {
  position: absolute;
  z-index: 100;
}

.icon-numbers::before {
  width: 100%;
}

.icon-experience-empty-multiple::before {
  width: 100%;
}

.icon-add_a_photo::before {
  margin-left: -1px;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
