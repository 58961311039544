.btn {
  font-weight: 500;
  transition: 300ms;

  &--primary {
    background: linear-gradient(180deg, #f800d8 0.12%, #ef0089 100%);
    box-shadow: 0px 0px 10px 0px #dd3aa1;

    &:hover:enabled {
      background: linear-gradient(180deg, #fe9983 0.12%, #ef0089 100%);
      box-shadow: 0px 0px 20px 0px #e5487e;
    }
  }

  &--tertiary,
  &--secondary {
    &:hover:enabled {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &--tertiary,
  &--secondary,
  &--icon {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
  }

  &__text,
  &__icon {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    &--none {
    }

    &--xxs {
      font-size: 0.5rem;
      line-height: 0.625rem;
    }

    &--xs {
      font-size: 0.625rem;
      line-height: 0.875rem;
    }

    &--sm {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &--md {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &--round {
    border-radius: 50%;
  }
}
