@import "~react-day-picker/lib/style.css";

$day-cell-size: 46px;

.DayPicker {
  display: block;
  font-family: Montserrat;
}

.DayPicker-Month {
  width: 100%;
  border-spacing: 4px;
  border-collapse: separate;
}

.DayPicker-Day {
  @apply text-white rounded-2xl bg-gradient-to-b from-card-item-active text-xs;
  font-weight: bold;
  width: $day-cell-size;
  height: $day-cell-size;

  &--outside {
    @apply text-opacity-50;
  }

}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply border border-solid border-opacity-02;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 19.11%, rgba(255, 255, 255, 0) 80.89%);
  background-clip: padding-box;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-primary4;
  background-image: none;
}

.DayPicker-NavBar {
  @apply flex justify-between absolute;
  top: 10px;
  left: 20px;
  right: 20px;
}

