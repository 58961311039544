@import '~common/assets/styles/variables';

@media only screen and (max-width: $mobile-screen-break) {
  div .form {
    max-width: 18.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: $mobile-screen-sm-break) {
  .instruction,
  .msg {
    display: none;
  }
}
