@import '~common/assets/styles/variables';

.bg--default {
}

.bg--current {
  background: linear-gradient(180deg, #ffc001 0%, #d4637c 100%);
}

.bg--completed {
  background: linear-gradient(157.92deg, #64ffff 14.57%, #00a980 103.42%);

  .icon {
    background: linear-gradient(157.92deg, #70b828 14.57%, #00b68a 103.42%);
  }
}

.bg--error {
  background: linear-gradient(
    321.83deg,
    #6100e9 -29.4%,
    #d20e08 31.78%,
    #f30fbd 87.85%
  );
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);

  .icon {
    background: linear-gradient(
      321.83deg,
      #6100e9 -29.4%,
      #d20e08 31.78%,
      #f30fbd 87.85%
    );
  }
}

@media only screen and (max-width: $mobile-screen-break) {
  .title {
    max-width: 5.5rem;
  }

  .container:nth-of-type(2) {
    .title {
      max-width: 4rem;
    }
  }
}
