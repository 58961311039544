@import '~common/assets/styles/variables';

.container {
  //background: linear-gradient(90deg, #620930 0%, #17073A 100%);
  background: linear-gradient(90deg, #560962 0%, #17073a 100%);
}

@media only screen and (max-width: $mobile-screen-break) {
  .wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: $mobile-screen-md-break) {
  .wrapper {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: $mobile-screen-sm-break) {
  .wrapper {
    padding: 0;
  }
}
