.bubble {
  border-radius: 50%;
  &--1 {
    background: linear-gradient(
      56.79deg,
      #3023ae 15.22%,
      #53a0fd 60.65%,
      #51ecd4 85.67%
    );
    box-shadow: 0 0 60px rgba(61, 82, 203, 0.8);
    width: 5.875em;
    height: 5.875em;
  }

  &--2 {
    background: linear-gradient(
      56.79deg,
      #3023ae 15.22%,
      #53a0fd 60.65%,
      #51ecd4 85.67%
    );
    box-shadow: 0 0 20px rgba(61, 82, 203, 0.8);
    width: 1.5em;
    height: 1.5em;
  }

  &--3 {
    background: #ffffff;
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.8);
    width: 0.625em;
    height: 0.625em;
  }

  &--4 {
    background: linear-gradient(
      56.79deg,
      #00f8a9 15.22%,
      #88d83c 63.82%,
      #eccd19 85.67%
    );
    box-shadow: 0 0 20px rgba(0, 248, 169, 0.8);
    width: 1.5em;
    height: 1.5em;
  }

  &--5 {
    background: linear-gradient(
      56.79deg,
      #f00540 15.22%,
      #ff549a 63.82%,
      #df49ff 85.67%
    );
    box-shadow: 0 0 20px rgba(240, 5, 64, 0.8);
    width: 4.5625em;
    height: 4.5625em;
  }

  &--6 {
    background: linear-gradient(
      56.79deg,
      #e5332a 15.22%,
      #ffd02d 60.58%,
      #fcea0d 85.67%
    );
    box-shadow: 0 4px 60px #b57831;
    width: 5.875em;
    height: 5.875em;
  }
}
