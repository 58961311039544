@import '~common/assets/styles/variables';

.devices {
  width: 55%;
  max-height: 45rem;
  min-height: 37rem;
  //max-width: 37rem;
}

.container {
  width: 29.5rem;
}

.container_inner {
  background: url('/common/assets/images/referral/bg-arrows.png');
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: $mobile-screen-break) {
  .container {
    width: 100%;
  }

  .title {
    max-width: 13rem;
  }
}

@media only screen and (min-width: $desktop-screen-lg) {
  .devices {
    max-width: 50%;
  }
}

@media only screen and (max-width: $mobile-screen-sm-break) {
  .container_inner {
    display: none;
  }
}
