.bg-rb {
  width: 18rem;
}

.bg-rt {
  width: 25rem;
}

.bg-l {
  width: 30rem;
}

.bg-l2 {
  width: 25rem;
}
